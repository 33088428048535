
// Check if user is logged in
var user = document.getElementById("user").value;

// Show or hide user search button
var pill_ids = ["v-pills-latest-tab", "v-pills-latest"];
if(user){
  pill_ids = ["v-pills-user-courses-tab", "v-pills-user-courses"];
}
pill_ids = ["v-pills-all-tab", "v-pills-all"]; // Delete later
pill_ids.forEach(function(pill_id) {
  var pill = document.getElementById(pill_id);
  if(pill) {
    pill.classList.add("active");
    pill.classList.add("show");
  }
});

// Set listeners for categories buttons
var categories = document.querySelectorAll("a.category");
[].forEach.call(categories, function(category) {
  category.addEventListener('click', function(){
    toggle_courses(category.id)
  }, false);
});

// Hide courses by category selected
function toggle_courses(category){
  var courses = document.querySelectorAll("div.course");
  [].forEach.call(courses, function(course){
    if (course.id != category && category != "all") {
      course.hidden = true;
    }else{
      course.hidden = false;
    }
  });
}

// Set hero create account event listeners
btn_hero_account = document.getElementById("btn_hero_account");
if(btn_hero_account != null) {
  btn_hero_account.addEventListener("click", toggle_register_modal);
}

function toggle_register_modal() {
  // Simulate clicks
  document.getElementById("btn_modal").click();
  document.getElementById("tab-register").click();
}
